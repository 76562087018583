/* Container principal do calendário */
.calendar-container {
  padding: 20px;
  background-color: #f4f6f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Container do cabeçalho */
.header-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

/* Botão de adicionar evento */
.btn-add-event {
  padding: 10px 20px;
  background-color: #1c99c7;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  transition: background-color 0.3s;
}

.btn-add-event:hover {
  background-color: #117a8b;
}

/* Container do formulário para adicionar evento */
.form-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.form-container h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.form-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-save-event {
  background-color: #28a745;
  color: white;
}

.btn-save-event:hover {
  background-color: #218838;
}

.btn-cancel-event {
  background-color: #e40c0c;
  color: rgb(255, 255, 255);
  margin-left: 10px;
}

.btn-cancel-event:hover {
  background-color: #ea4242;
}

/* Estilo para o modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.752); /* Fundo bem escuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Garantir que o modal esteja bem acima de outros elementos */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Sombra mais intensa para destaque */
  opacity: 1; /* Garantindo que o modal tenha opacidade total */
  z-index: 10000; /* Certificando-se de que o modal está acima de outros elementos */
}

.modal-content h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.modal-content p {
  font-size: 14px;
  margin: 5px 0;
}

.btn-close {
  padding: 10px 20px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

.btn-close:hover {
  background-color: #c9302c;
}
.rbc-toolbar-label {
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
}

/* Container da legenda */
.legend-container {
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
}

.legend-item {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.legend-color {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 3px;
}
.rbc-toolbar .rbc-btn-group:first-child {  
  display: flex;
  gap: 0px; /* Remove espaço extra entre botões alterações da nav */
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(1) {
  order: 2; /* Move o "Anterior" para depois */
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(2) {
  order: 1; /* Move o "Hoje" para antes */
}

.rbc-toolbar .rbc-btn-group:first-child button:nth-child(3) {
  order: 3; /* O "Próximo" permanece na posição correta */
}
 
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9); /* Fundo branco translúcido */
  backdrop-filter: blur(5px); /* Efeito de desfoque no fundo */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}


.modal-content {
  background: #f0f0f0; /* Fundo cinza claro para o pop-up */
  padding: 20px;
  border-radius: 10px;
  width: 500px; /* Largura do pop-up */
  max-height: 90%; /* Altura máxima */
  overflow-y: auto; /* Scroll para listas longas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para destaque */
}

.modal-content h3 {
  margin-bottom: 20px;
  color: #333333; /* Cor do texto */
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.modal-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-content li {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background: #ffffff; /* Fundo branco sólido dos itens */
  display: flex;
  flex-direction: column;
  gap: 5px; /* Espaçamento entre as linhas */
}

.modal-content li p {
  margin: 0;
  color: #555555;
  font-size: 14px;
}

.modal-content li p strong {
  color: #333333;
  font-weight: bold;
}

.modal-content button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #007bff; /* Azul para o botão */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.modal-content button:hover {
  background-color: #0056b3; /* Azul mais escuro ao passar o mouse */
}

.modal-content button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #ff4d4f; /* Vermelho */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
}

.modal-content button:hover {
  background-color: #d9363e; /* Vermelho mais escuro ao passar o mouse */
}

.close-button {
  position: absolute;
  top: -12px; /* Move o botão mais para cima */
  right: 10px; /* Mantém a distância da borda direita */
  background: none;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff; /* Cor branca para o texto */
  background-color: #ff4d4f; /* Fundo vermelho */
  border-radius: 5px; /* Bordas arredondadas */
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra para destaque */
}

.close-button:hover {
  background-color: #d9363e; /* Vermelho mais escuro ao passar o mouse */
  transform: scale(1.1); /* Efeito de aumento ao passar o mouse */
}

.btn-close{
  top: -200px; /* Move o botão mais para cima */
  right: 1000px; /* Mantém a distância da borda direita */
}

.btn-organize-availability {
  background-color: #1c99c7; /* Azul */
  color: #ffffff; /* Branco */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.btn-organize-availability:hover {
  background-color: #1582a8; /* Azul mais escuro no hover */
}

.btn-organize-availability:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.header-container button {
  margin-right: 10px; /* Adiciona espaço entre os botões */
}

.header-container {
  display: flex; /* Garante que os botões fiquem alinhados horizontalmente */
  gap: 10px; /* Espaçamento uniforme entre os elementos filhos */
}

.availability-form {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1; /* Garante que os campos tenham tamanhos iguais */
  max-width: 200px; /* Limita a largura dos campos */
}

.form-group label {
  font-weight: bold;
  font-size: 13px; /* Reduz o tamanho da fonte */
}

.form-group input {
  padding: 8px; /* Reduz o preenchimento interno */
  font-size: 13px; /* Ajusta o tamanho do texto */
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .form-group {
    flex: 1;
  }

  .availability-form {
    flex-wrap: nowrap;
  }
}





































/*Estilos para os eventos no calendário */
.rbc-event {
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rbc-event:hover {
  opacity: 0.8;
}

/* Estilo do calendário */
.rbc-calendar {
  font-family: 'Roboto', sans-serif;
}

/* Estilos para a legenda no calendário */
.rbc-day-bg {
  background-color: #f4f6f9 !important;
}

.rbc-header {
  font-weight: bold;
  font-size: 16px;
}


/* Customização do botão para eventos do backend e criados no formulário */
.rbc-event-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Botão para adicionar horários no modal */
.btn-add-slot {
  background-color: #4CAF50; /* Verde */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.btn-add-slot:hover {
  background-color: #45a049; /* Verde mais escuro no hover */
}

/* Botão para remover horários */
.btn-remove-slot {
  background-color: #f44336; /* Vermelho */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.btn-remove-slot:hover {
  background-color: #d32f2f; /* Vermelho mais escuro no hover */
}

/* Botão para fechar o modal */
.btn-close-modal {
  background-color: #1E88E5; /* Azul */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  display: block;
  margin: 20px auto;
}

.btn-close-modal:hover {
  background-color: #1565C0; /* Azul mais escuro no hover */
}

.form-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.form-title {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

label {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

input,
textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

textarea {
  resize: vertical;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #6a0dad;
  box-shadow: 0 0 5px rgba(106, 13, 173, 0.5);
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-save-event {
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.btn-cancel-event {
  background-color: #dc3545;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.btn-save-event:hover {
  background-color: #218838;
}

.btn-cancel-event:hover {
  background-color: #c82333;
}
