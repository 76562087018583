.App {
  font-family: var(--Poppins) !important;
  font-weight: 400;

  color: var(--text-color0);
  background-color: var(--background-color0);
}

.MuiTypography-root {
  font-family: var(--Poppins) !important;
}

.Transition-1 {
  transition: all 500ms cubic-bezier(0.67, 0.01, 0.34, 0.99);
}

.Transition-2 {
  transition: all 600ms ease;
}

.Transition-3 {
  transition: all 100ms ease;
}

.scrollTransition {
  opacity: 0;
  transform: translateY(100px);
}

.remove-underline:hover {
  text-decoration: none !important;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: rgb(var(--accent-color1));
}

a:hover {
  text-decoration: underline;
}

img {
  display: block;
  object-fit: contain;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

button:hover,
input[type="submit"]:hover {
  filter: brightness(1.18);
}

button:active,
input[type="submit"]:active {
  position: relative;
  transform: translateY(0.2rem);
  transition: none !important;
}

::selection {
  background-color: rgba(var(--accent-color1), 0.8);
  color: white;
}

#root {
  --Poppins: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --Fira: "Fira Mono", monospace;
}

/* start scrollbar */
::-webkit-scrollbar {
  width: 1rem;
  height: 1.125rem;
  background-color: var(--background-color0) !important;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  height: 0.375rem;
  border: 0.25rem solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 0.438rem;
  border-radius: 0.5rem;
  background-color: var(--background-color2) !important;
  -webkit-box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
    inset 1px 1px 0px rgb(0 0 0 / 5%);
  box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
    inset 1px 1px 0px rgb(0 0 0 / 5%);
}

/* fonts */

/* Freight Big Pro */
@font-face {
  font-family: "Fira Mono";
  font-weight: 700;
  src: local("fira_mono"),
    url("./fonts/fira_mono/FiraMono-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Mono";
  font-weight: 400;
  src: local("fira_mono"),
    url("./fonts/fira_mono/FiraMono-Regular.ttf") format("truetype");
}

/* fonts */
@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("poppins"),
    url("./fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: local("poppins"),
    url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: local("poppins"),
    url("./fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
}
