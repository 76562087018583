.demoContainer {
  display: flex;
  flex-direction: column;
  
  box-shadow: var(--box-shadow1);
  border-radius: 1rem;
  overflow: hidden;
  
  width: max-content;
  max-width: 90%;

  height: max-content;
  max-height: 60%;
}

.vcContainer {
  display: flex;
  background: var(--background-color1);
  padding: 1.6rem;
  
  gap: 1rem;
  overflow-y: auto;
}

.headerSection {
  display: flex;
  height: 3.2rem;
  align-items: center;
  background-color: var(--background-color2);
  padding: 0 1.6rem;
  gap: 0.4rem;
}

.headerSection span {
  height: 0.8rem;
  width: 0.8rem;
  background-color: var(--background-color0);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.headerSection  span:nth-child(1):hover { background-color: #6fa1e2; }
.headerSection  span:nth-child(2):hover { background-color: #a172ec; }
.headerSection  span:nth-child(3):hover { background-color: #e46b89; }

.videoContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 46rem;
}

.vcActionsContainer {
  display: flex;
  gap: 1rem;
  width: max-content;
  margin: 0 auto;
}

.vcActionsContainer div {
  background-color: var(--background-color0);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  height: 3rem;
  width: 3rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(var(--accent-color1), 0.6);
  cursor: pointer;
}

.vcActionsContainer div:hover {
  color: rgba(var(--accent-color1), 0.8);
  transform: scale(1.1);
}

.chatContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  background-color: var(--background-color0);

  width: 100%;
  max-width: 22rem;
  height: 100%;
  border-radius: 1rem;
  padding: 1rem;
}

.chatMessagesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.textareaContainer {
  display: flex;
  gap: 0.5rem;

  width: 100%;
}