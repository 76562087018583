.dark-mode {
  --background-color0: #19191e;
  --background-color1: #24242b;
  --background-color2: #2b2b30;
  --background-color3: #3f3f46;

  --accent-color0: 53, 177, 252;
  --accent-color1: 161, 166, 255;
  --accent-color2: 143, 149, 255;

  --text-color0: #d4d2d2;
  --text-color1: #e6e6e6;
  --text-color2: #f2f1f6;

  --navbar-color: rgba(36, 36, 43, 0.96);

  --box-shadow1: rgba(23, 22, 27, 0.8) 0px 28px 50px 0px;
}

.white-mode {
  --background-color0: #fff;
  --background-color1: #f3f5fc;
  --background-color2: #d7dae4;
  --background-color3: #bdc0c9;

  --accent-color0: 0, 166, 255;
  --accent-color1: 14, 23, 180;
  --accent-color2: 14, 23, 180;

  --text-color0: #232c33;
  --text-color1: #394147;
  --text-color2: #4f565c;

  --navbar-color: rgba(255, 255, 255, 0.96);

  --box-shadow1: rgba(17, 12, 46, 0.15) 0px 28px 50px 0px;
}
