.participantContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-grow: 1;
  flex-basis: 20rem;
  background-color: rgba(var(--accent-color1), 0.08);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px 0px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: all 50ms ease-in-out;
}

.participantContainer:hover {
  background-color: rgba(var(--accent-color1), 0.12);
  animation: jump 200ms forwards;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-0.3rem);
  }
  100% {
    transform: translateY(0);
  }
}

.participantContainer .mediaSource {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  border: none;
  border-radius: 1rem;
  overflow: hidden;
}

.participantContainer .userAvatar {
  height: 70%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.participantContainer .userName {
  width: max-content;
  font-weight: bold;
  font-size: 1.2rem;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px 0px;
  padding: 0.2rem 0.8rem;
  border-radius: 1rem;
}
