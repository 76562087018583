/* Estilo Principal */
.compartilhamento-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  max-width: 700px;
  margin: 20px auto;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

h1 {
  color: #1a73e8;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

h2 {
  color: #333;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.section {
  margin-bottom: 25px;
}

/* Botões */
button {
  background-color: #1a73e8;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #1558c6;
  transform: scale(1.05);
}

button:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px rgba(26, 115, 232, 0.3);
}

/* Botão Remover */
button.remove-button {
  background-color: #e53935;
  padding: 6px 12px;
}

button.remove-button:hover {
  background-color: #c62828;
}

/* Inputs */
input[type="text"], select, .file-input {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 0.9rem;
  margin-top: 8px;
  margin-bottom: 15px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus, select:focus, .file-input:focus {
  border-color: #1a73e8;
  outline: none;
}

label {
  font-weight: bold;
  margin-bottom: 6px;
  display: block;
}

/* Lista de Participantes */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li {
  background-color: #f9f9f9;
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Links */
a {
  color: #1a73e8;
  text-decoration: none;
}

a:hover {
  color: #1558c6;
  text-decoration: underline;
}

/* Layout Responsivo */
@media (max-width: 768px) {
  .compartilhamento-container {
      padding: 15px;
  }

  h1 {
      font-size: 1.8rem;
  }

  h2 {
      font-size: 1.3rem;
  }
}
