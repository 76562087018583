/* Estilo geral do formulário */
form {
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Títulos e descrições do formulário */
form h1 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: #333;
}

form p {
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  color: #666;
}

/* Grupos de formulário */
.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-group-inline {
  display: flex;
  gap: 20px;
}

/* Inputs */
.input-field {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #007bff;
}

/* Labels */
.label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

/* Tabela de convidados */
.table-container {
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-container th {
  background: #f9f9f9;
  font-weight: bold;
}

.table-container td {
  background: #fff;
}

/* Botões */
.button {
  padding: 8px 15px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button.remove {
  background-color: #ff4d4f;
  color: #fff;
}

.button.remove:hover {
  background-color: #d9383b;
}

.button.add {
  background-color: #007bff;
  color: #fff;
}

.button.add:hover {
  background-color: #0056b3;
}

/* Ações da tabela */
.table-actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

/* Botões no formulário */
.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
